.background-container {
  background-image: url("../../assets/astronaut.jpg");
  height: 100vh;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.landing-block {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-block-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1200px;
  z-index: 1;
}

.landing-block h1,
.landing-block p {
  color: white;
  font-size: 61.45px;
  animation: fadeIn 2s ease-out; 
}

.logo::before {
  content: "CosmicDot•";
  position: absolute;
  filter: blur(5px);
}

@keyframes fadeIn {
  0% { opacity: 0; }
  40% { opacity: 0; }
  100% { opacity: 1; }
}

@media screen and (max-width: 450px) {

  .landing-block h1,
  .landing-block p {
    font-size: 2rem;
  }
}
