.site-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(182.65deg, #2A0B46 -8.6%, #0C1011 13.89%, #2D0659 52.36%, #170233 83.14%, #000000 105.04%);
  min-height: 1800px;
}

.site-container {
  max-width: 1200px;
  padding: 0 20px;
}

.pattern-bg {
  width: 100%;
  background-image: url("./assets/pattern.webp");
  background-position-y: 100vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  background-position-x: center;
  /* padding: 200px 0px; */
}

.site-content {
  /* background-image: url("./assets/pattern.png");
  background-position-y: -300px;
  padding: 200px 0px; */
}

h1,
p {
  color: white;
}

h1 {
  font-size: 72px;
}


.information-block {
  margin: 200px 0px;
}


@media screen and (max-width: 800px) {
  .site-container {
    width: 100vw;
    padding: 0 20px;
  }
}
