.what-container {
  display: flex;
  justify-content: flex-end;
}

.what-image {
  width: 250px;
}

.question-mark {
  background-image: url('../../assets/question-mark.png');
  position: absolute;
  width: 300px;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: -100px;
  margin-left: -100px;
}

.cube-image {
  width: 500px;
  position: relative;
  left: -300px;
  top: -100px
}

.title-question-mark {
  z-index: 1;
}

.trailsText {
  color: black;
  will-change: transform, opacity;
  overflow: hidden;
}

.trailsText>div {
  padding-right: 0.05em;
  overflow: hidden;
}


@media screen and (max-width: 750px) {
  .what-image {
    display: none;
  }

  .cube-image {
    position: absolute;
    left: 130px;
    top: unset;
    opacity: .3;
  }
}
