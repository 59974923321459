/* * {
  font-family: "Lexend", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", sans-serif;
}
.logo {
  font-family: "Poppins", system-ui;
}
.landing-block p {
  font-family: "Poiret One", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", sans-serif;
} */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
