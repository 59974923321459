.contacts-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.art-img {
  flex: 2;
  max-height: 550px;
}

.art-img>img {
  max-width: 300px;
  min-width: 250px;
  float: right;
  position: relative;
}

#art-1 {
  top: -300px;
  left: 20%;
}

.contacts-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 3;
  position: relative;
  top: 20px;
}

.contacts-content>a>button {
  background: linear-gradient(180.42deg, #250439 -32.21%, #943FFF 94.78%);
  box-shadow: 0px 9px 52px #301E44;
  border-radius: 50px;
  border: none;
  color: white;
  width: 200px;
  padding: 10px 30px;
}

.contacts-content>h1 {
  text-align: center;
}

.contacts-content>p {
  text-align: center;
  color: #3D0D7D;
}

@media screen and (max-width:750px) {
  .art-img {
    position: absolute;
    margin-top: -90px;
    position: absolute;
    width: 90px;
  }

  .contacts-content {
    z-index: 1;
    text-shadow: 0px 9px 52px #301E44;
  }

  #art-1 {
    top: -240px;
    left: 120px;
  }

  #art-2 {
    top: 50px;
    left: 50px;
  }

  .art-img>img {
    width: 30vw;
  }
}
