.footer-container {
  margin: 50px;
  margin-bottom: 10px;
}

.footer-container > *{
  color: white;
  text-align: center;
}

.footer-container > p {
  font-size: 0.8rem;
}
