.partners-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  background: linear-gradient(163.76deg, #000000 -31.73%, #7363D2 282.79%);
  border-radius: 103.035px;
}

.partners-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0px 50px;
}

.services,
.partner {
  flex: 1;
}

.partner {
  text-align: center;
}

.services-container,
.partners {
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 100px;
  align-items: center;
  width: 100%;
}

.services>ul {
  list-style-type: none;
  margin: 0;
}

.services-container {
  border-left: 1px solid white;
}

.other-partners {
  width: 100%;
  text-align: center;
}

.vertical-content{
  flex:1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.anon-partners{
  color: #3D0D7D;
}

.partners-box>* {
  color: white;
}

.partners{
  margin-top: 30px;
}

@media screen and (max-width: 750px) {
  .partners-content {
    flex-direction: column;
    margin: 0;
  }

  .partners-box {
    border-radius: 10px;
  }

  .services-container {
    border-left: 0px;
    border-top: 1px solid white;
    margin-top: 20px;
    padding-top: 20px;
  }

  .services-container,
  .partners {
    /* flex-direction: column; */
    align-items: center;
    width: 100%;
  }

  ul {
    padding-left: 0rem;
    text-align: center;
  }
}


@media screen and (max-width: 450px) {
  .partners-content {
    flex-direction: column;
    margin: 0;
  }

  .partners-box {
    border-radius: 10px;
  }

  .services-container {
    border-left: 0px;
    border-top: 1px solid white;
    margin-top: 20px;
  }

  .services-container,
  .partners {
    flex-direction: column;
    align-items: center;
  }

  ul {
    padding-left: 0rem;
    text-align: center;
  }
}
