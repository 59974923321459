.who-container {
  display: flex;
  justify-content: flex-end;
}

.who-image {
  width: 250px;
}

.ball-image{
  width: 500px;
  position: relative;
  left: 60px;
  top: -100px
}

@media screen and (max-width: 750px) {
  .who-image{
    width: 0px;
  }

  .ball-image{
    display: none;
  }
}
